import React from "react";

import practice1 from "../img/practice1.png";

const OurSpecialties = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center overflow-hidden bg-white">
      <div className="w-2/3 flex flex-col justify-center items-center py-16">
        <div className="w-full flex flex-row justify-center items-start flex-wrap mb-8">
          <img className="pr-4" alt="practice-1" src={practice1} />
          <div className="flex flex-col justify-start items-start pl-4">
            <p className="text-gray-600 font-semibold text-md mb-2">
              Our Internal Medicine specialties include the following
              conditions:
            </p>
            <p className="text-gray-600 text-md mb-1">Diabetes Melitus</p>
            <p className="text-gray-600 text-md mb-1">Hypertension</p>
            <p className="text-gray-600 text-md mb-1">
              Coronary Artery Disease
            </p>
            <p className="text-gray-600 text-md mb-1">Thyroid diseases</p>
            <p className="text-gray-600 text-md mb-1">COPD</p>
            <p className="text-gray-600 text-md mb-1">Arthritis</p>
            <p className="text-gray-600 text-md mb-1">
              Gastroesophageal Reflux Disease
            </p>
            <p className="text-gray-600 text-md mb-1">Chronic Kidney Failure</p>
            <p className="text-gray-600 text-md mb-1">Hypercholestrolemia</p>
          </div>
        </div>
        <div className="w-full lg:w-2/3 flex flex-col justify-start items-start">
          <p className="text-gray-600 text-md mb-4">
            <b className="font-semibold">Diabetes Melitus: </b>
            Type 1 diabetes, once known as juvenile diabetes or
            insulin-dependent diabetes, is a chronic condition in which the
            pancreas produces little or no insulin, a hormone needed to allow
            sugar (glucose) to enter cells to produce energy. Type 2 diabetes,
            which is far more common, occurs when the body becomes resistant to
            the effects of insulin or doesn’t make enough insulin.
          </p>
          <p className="text-gray-600 text-md mb-4">
            Various factors may contribute to type 1 diabetes, including
            genetics and exposure to certain viruses. Although type 1 diabetes
            typically appears during adolescence, it can develop at any age.
          </p>
          <p className="text-gray-600 text-md mb-12">
            Despite active research, type 1 diabetes has no cure, although it
            can be managed. With proper treatment, people who have type 1
            diabetes can expect to live longer, healthier lives than in the
            past.
          </p>

          <p className="text-gray-600 text-md mb-4">
            <b className="font-semibold">Hypertension: </b>
            High blood pressure is a common condition in which the force of the
            blood against your artery walls is high enough that it may
            eventually cause health problems, such as heart disease.
          </p>
          <p className="text-gray-600 text-md mb-4">
            Blood pressure is determined by the amount of blood your heart pumps
            and the amount of resistance to blood flow in your arteries. The
            more blood your heart pumps and the narrower your arteries, the
            higher your blood pressure.
          </p>
          <p className="text-gray-600 text-md mb-4">
            You can have high blood pressure (hypertension) for years without
            any symptoms. Uncontrolled high blood pressure increases you risk of
            serious health problems, including heart attack and stroke.
          </p>
          <p className="text-gray-600 text-md mb-12">
            High blood pressure typically develops over many years, and it
            affects nearly everyone eventually. Fortunately, high blood pressure
            can be easily detected. And once you know you know you have high
            blood pressure, you can work with your doctor to control.
          </p>

          <p className="text-gray-600 text-md mb-4">
            <b className="font-semibold">Coronary Artery Disease: </b>
            Coronary artery disease develops when your coronary arteries – the
            major blood vessels that supply your heart with blood, oxygen and
            nutrients – become damaged or diseased. Cholesterol containing
            deposits (plaques) on your arteries are usually to blame for
            coronary artery disease.
          </p>
          <p className="text-gray-600 text-md mb-4">
            When plaques build up, they narrow your coronary arteries, causing
            your heart to receive less blood. Eventually, diminished blood flow
            may cause chest pain (angina), shortness of breath or other coronary
            artery disease symptoms. A complete blockage can cause a
            heart-attack.
          </p>
          <p className="text-gray-600 text-md mb-12">
            Because coronary artery disease often develops over decades, it can
            virtually go unnoticed until it produces a heart attack. But there’s
            plenty you can do to prevent and treat coronary artery disease.
            Start by committing to a healthy lifestyle.
          </p>

          <p className="text-gray-600 text-md mb-4">
            <b className="font-semibold">Thyroid diseases: </b>
            The thyroid gland is located in the front of the neck, just below
            the voice box (larynx). It produces chemicals (hormones) that help
            the body control metabolism. Thyroid hormone is normally produces in
            response to another hormone by the pituitary gland.
          </p>
          <p className="text-gray-600 text-md mb-4">
            There are four main types of thyroid disease:
          </p>
          <ul className="mb-12 text-gray-600 text-md list-inside list-disc">
            <li>Hyperthyroidism – too much thyroid hormone</li>
            <li>Hypothyroidism – too little thyroid hormone</li>
            <li>Benign – (noncancerous) thyroid disease</li>
            <li>Thyroid cancer</li>
          </ul>

          <p className="text-gray-600 text-md mb-4">
            <b className="font-semibold">COPD: </b>
            Chronic obstructive pulmonary disease (COPD) refers to a group of
            lung diseases that block airflow as you exhale and make it
            increasingly difficult for you to breathe.
          </p>
          <p className="text-gray-600 text-md mb-4">
            Emphysema and chronic asthmatic bronchitis are the two main
            conditions to make up COPD. In all cases, damage to your airways
            eventually interferes with the exchange of oxygen and carbon dioxide
            in your lungs.
          </p>
          <p className="text-gray-600 text-md mb-12">
            COPD is a leading cause of death and illness worldwide. Most COPD is
            caused by long-term smoking and can be prevented by not smoking or
            quitting soon after you start. This damage to your lungs can’t be
            reversed, so treatment focuses on controlling symptoms and
            minimizing further damage.
          </p>

          <p className="text-gray-600 text-md mb-4">
            <b className="font-semibold">Arthritis: </b>
            Arthritis is inflammation of one or more of your joints. The main
            symptoms of arthritis are joint pain and stiffness, which typically
            worsen with age. The two most common types of arthritis are
            osteoarthritis and rheumatoid arthritis.
          </p>
          <p className="text-gray-600 text-md mb-4">
            Osteoarthritis is usually caused by normal wear and tear, which
            rheumatoid arthritis is an autoimmune disorder. Other types of
            arthritis can be caused by uric acid crystals, infections or even an
            underlying disease – such as psoriasis or lupus.
          </p>
          <p className="text-gray-600 text-md mb-12">
            Treatments vary, depending on the type of arthritis. The main goals
            of arthritis treatments are to reduce symptoms and improve quality
            of life.
          </p>

          <p className="text-gray-600 text-md mb-4">
            <b className="font-semibold">Gastroesophageal Reflux Disease: </b>
            (GERD) is a chronic digestive disease that occurs when stomach acid
            or, occasionally, bile flows back (refluxes) into your food pipe
            (esophagus). The backwash of acid irritates the lining of your
            esophagus and causes GERD signs and symptoms.
          </p>
          <p className="text-gray-600 text-md mb-4">
            Signs and symptoms of GERD include acid reflux and heartburn. Both
            are common digestive conditions that many people experience from
            time to time. When these signs and symptoms occur at least twice a
            week or interfere with your daily life, doctors call this GERD.
          </p>
          <p className="text-gray-600 text-md mb-12">
            Most people can manage the discomfort of heartburn with lifestyle
            changes and over-the-counter medications. But for people with GERD,
            these remedies may off only temporary relief. People with GERD may
            need stronger medications, even surgery, to reduce symptoms.
          </p>

          <p className="text-gray-600 text-md mb-4">
            <b className="font-semibold">Chronic Kidney Failure: </b>
            describes the gradual loss of kidney function. Your kidneys filter
            wastes and excess fluids from your blood which are then excreted in
            your urine. When chronic kidney failure damages your kidneys,
            dangerous levels of fluid and waste can accumulate in your body.
          </p>
          <p className="text-gray-600 text-md mb-4">
            In the early stages of chronic kidney failure, you may have a few
            signs or symptoms. Chronic kidney failure may not become apparent
            until your kidney function is significantly impaired.
          </p>
          <p className="text-gray-600 text-md mb-12">
            Treatment for chronic kidney failure, also called chronic kidney
            disease, focuses on slowing the progression of kidney damage,
            usually by controlling the underlying cause. Chronic kidney failure
            can progress to end-stage kidney disease, which is fatal without
            artificially filtering (dialysis)or a kidney transplant.
          </p>

          <p className="text-gray-600 text-md mb-4">
            <b className="font-semibold">Hypercholestrolemia: </b>
            Cholesterol is a waxy substance that’s found in the fats (lipids) in
            your blood. While your body needs cholesterol to continue building
            healthy cells, having high cholesterol can increase your risk of
            heart disease.
          </p>
          <p className="text-gray-600 text-md mb-4">
            When you have high cholesterol, you may develop fatty deposits in
            your blood vessels. Eventually, these deposits make it difficult for
            enough blood to flow through your arteries. You heart may not get as
            much oxygen-rich blood as it needs, which increases the risk of
            heart attack. Decreased blood flow to your brain can cause a stroke.
          </p>
          <p className="text-gray-600 text-md">
            High cholesterol (hypercholesterolemia) can be inherited, but it is
            often preventable and treatable. A healthy diet, regular exercise
            and sometimes medication can go a long way toward reducing high
            cholesterol.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurSpecialties;
