import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import OurSpecialties from "../../components/OurSpecialties";
import Information from "../../components/Information";
import Map from "../../components/Map";

const Specialties = () => {
  return (
    <Layout>
      <PageHeader text="Our Specialties" />
      <OurSpecialties />
      <Conditions />
      <Information />
      <Map />
    </Layout>
  );
};

export default Specialties;
